import React from "react"

// should have the containing object of this use
// className="overflow-hidden position-relative"
export default function DiagonalSlashSVG() {
  return (
    <div className="watermark-holder">
      <svg width="800" height="600" xmlns="http://www.w3.org/2000/svg">
        <g>
          <title>Layer 1</title>
          <rect
            transform="rotate(40 300 0)"
            stroke-width="0"
            id="svg_1"
            height="37"
            width="340"
            y="158"
            x="178"
            stroke="#000"
            fill="#ffffff"
          />
        </g>
      </svg>
    </div>
  )
}
