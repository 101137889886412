const navbarLinks = [
  { name: "Home", href: "/#home" },
  { name: "Tech Projects", href: "/#techProjects" },
  //   { name: "Audio + Music", href: "/audioMusic" },
  { name: "Blog", href: "/blog" },
  { name: "About", href: "/about" },
]

const navbarButtons = [{ name: "Contact", href: "/contact" }]

const footerLinks = [
  { name: "Home", href: "/#home" },
  { name: "Tech Projects", href: "/#techProjects" },
  //   { name: "Audio + Music", href: "/audioMusic" },
  { name: "Blog", href: "/blog" },
  { name: "About Me", href: "/about" },
  { name: "Resume", href: "/#resume" },
  { name: "Contact Me", href: "/contact" },
]

const heroLinks = [
  {
    name: "Tech Projects",
    href: "/#techProjects",
    iconClass: "fas fa-laptop-code altButtonIconToAnimate",
  },
  //   {
  //     name: "Audio + Music",
  //     href: "/audioMusic",
  //     iconClass: "fas fa-compact-disc buttonIconToAnimate",
  //   },
  {
    name: "Blog",
    href: "/blog",
    iconClass: "fas fa-pen-nib buttonIconToAnimate",
  },
]

export default {
  navbarLinks: navbarLinks,
  navbarButtons: navbarButtons,
  footerLinks: footerLinks,
  heroLinks: heroLinks,
}
