import React from "react"
import Layout from "../components/Layout"
import Head from "../components/Head"
import Stripe from "../components/Stripe"
import "../styles/global.css"

export default function contact() {
  return (
    <div className="pageContent">
      <Head />
      <Layout>
        <div className="startAfterNavbar">
          <h1 className="my-4 mx-3">Contact Me</h1>
        </div>

        <Stripe title="Coming Soon!"><p>Currently being worked on 😮. For now, please message me on LinkedIn (link in the page footer).</p></Stripe>
      </Layout>
    </div>
  )
}
